import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg width="149" height="10" viewBox="0 0 149 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.7014 10C24.6209 9.07169 2.06469 10.1081 0.425173 9.82831C-0.913343 9.45317 1.22572 2.84689 2.46817 1.87407C2.90367 1.52436 3.40961 1.47351 4.56881 1.63247C6.40686 1.93131 30.1735 1.33997 30.1735 1.33997C30.4873 1.54344 31.4288 0.856744 31.6017 1.10472C32.1461 1.68332 70.6684 0.894891 73.9539 0.8631C75.9456 0.844025 74.3701 1.23824 76.7141 1.07928C81.7096 0.761364 92.597 1.06657 96.382 0.704142C99.4113 0.405302 100.065 0.392594 102.972 0.63421C105.944 0.850392 121.743 0.538832 123.549 0.074676C123.991 -0.0524901 124.331 -0.00797814 124.331 0.138263C124.331 0.170054 135.468 0.278142 135.519 0.392591C135.602 0.538832 135.942 0.608769 136.256 0.507036C136.838 0.354437 148.693 -0.198737 148.879 0.227269C149.461 1.44806 147.828 6.64915 146.451 7.91446C145.042 9.26878 137.306 7.74915 131.087 8.56302L102.133 8.64568L95.9273 9.15433C95.2164 8.71561 92.4817 9.82196 90.8294 9.03353C90.1313 8.72197 85.1999 8.67745 84.1112 9.00809C83.1634 9.26242 57.9365 8.62023 55.3491 9.50403C50.738 8.76647 32.0372 9.82197 27.7014 10Z" fill="#FFA700" />
    </svg>

  </span>
)


export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg width="237" height="10" viewBox="0 0 237 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M44.062 10C39.1621 9.07169 3.28411 10.1081 0.676282 9.82831C-1.45277 9.45317 1.94964 2.84689 3.92588 1.87407C4.61859 1.52436 5.42334 1.47351 7.26716 1.63247C10.1908 1.93131 47.9941 1.33997 47.9941 1.33997C48.4933 1.54344 49.9907 0.856744 50.2658 1.10472C51.1317 1.68332 112.405 0.894891 117.631 0.8631C120.799 0.844025 118.293 1.23824 122.022 1.07928C129.968 0.761364 147.285 1.06657 153.306 0.704142C158.124 0.405302 159.163 0.392594 163.788 0.63421C168.515 0.850392 193.645 0.538832 196.518 0.074676C197.221 -0.0524901 197.761 -0.00797814 197.761 0.138263C197.761 0.170054 215.476 0.278142 215.557 0.392591C215.69 0.538832 216.23 0.608769 216.729 0.507036C217.656 0.354437 236.512 -0.198737 236.807 0.227269C237.734 1.44806 235.136 6.64915 232.946 7.91446C230.705 9.26878 218.399 7.74915 208.508 8.56302L162.453 8.64568L152.582 9.15433C151.452 8.71561 147.102 9.82196 144.474 9.03353C143.363 8.72197 135.519 8.67745 133.788 9.00809C132.28 9.26242 92.154 8.62023 88.0386 9.50403C80.704 8.76647 50.9585 9.82197 44.062 10Z" fill="#FFA700" />
    </svg>
  </span>
)
