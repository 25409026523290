import React, { useEffect, useRef } from "react"
import { TitleUnderlineOne, TitleUnderlineTwo } from "./titleUnderline"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Slider from "react-slick"
import useNearScreen from "@hooks/useNearScreen"
import imageOneDesktop from "./images/imagesDesktop/imageOne.webp"
import imageTwoDesktop from "./images/imagesDesktop/imageTwo.webp"
import imageThreeDesktop from "./images/imagesDesktop/imageThree.webp"
import imageFourDesktop from "./images/imagesDesktop/imageFour.webp"
import { Link } from "gatsby"
import imageOneMobile from "./images/imagesMobile/imageOne.webp"
import imageTwoMobile from "./images/imagesMobile/imageTwo.webp"
import imageThreeMobile from "./images/imagesMobile/imageThree.webp"
import imageFourMobile from "./images/imagesMobile/imageFour.webp"


const Experience = ({ location }) => {
  const { isNearScreen, fromRef } = useNearScreen({ distance: "10px" })
  const sliderRef = useRef()

  useEffect(() => {
    if (isNearScreen) sliderRef.current.slickPlay()
  }, [isNearScreen])

  const activateSliderTestimonies = {
    arrows: true,
    autoplay: false,
    // autoplay: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 14000,
  }

  const data = [
    {
      title: "Implementa un autoservicio",
      description: "Implementa un autoservicio con chatbots con IA para responder de manera inmediata todas las consultas de los usuarios sin intervención humana, mejorando la eficiencia operativa y permitiendo que tu equipo de soporte se concentre en tareas más complejas y de mayor valor.",
      image: imageOneMobile,
      background: "#EEEAFB"
    },
    {
      title: "Enrutamiento a agentes en vivo",
      description: "Permite que tus clientes sean atendidos por agentes humanos. Analiza la complejidad de la consulta, el historial del cliente y las especializaciones de los agentes para asegurar que cada cliente sea atendido por el agente más calificado para las necesidades específicas.",
      image: imageTwoMobile,
      background: "#FADEDD"
    },
    {
 
      title: "Refuerza estrategias Cross y up selling",
      description: "Identifica oportunidades de venta cruzada o adicional, basándote en el comportamiento de compra y las preferencias del cliente. Ofrece recomendaciones personalizadas durante las interacciones para maximizar el valor del cliente a largo plazo.",
      image: imageFourMobile,

      background: "#EEEAFB"
    },
    {
      title: "Integraciones con APIs",
      description: "Utiliza nuestras APIs para crear integraciones con tus plataformas internas como CRM o ERP, como Salesforce, HubSpot, Zoho y más; permitiendo un intercambio de datos en tiempo real. Esto facilita una visión 360 grados del cliente y personaliza la interacción.",
      image: imageThreeMobile,
      background: "#FADEDD"
    },
  ]

  return (
    <section className="container-experience-webchat">
      <p className="container-experience-webchat-title">
      Crea <TitleUnderlineOne underline="procesos" /> <TitleUnderlineTwo underline="automatizados" /> que promuevan más ventas en las interacciones con tus clientes</p>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-webchat-cardsOne">
        {/* una sola fila */}
        <section className="container-experience-webchat-cardsOne-card" style={{ background: "#EEEAFB", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-webchat-cardsOne-card-title">Implementa un autoservicio</p>
            <p className="container-experience-webchat-cardsOne-card-description">
            Implementa un autoservicio con chatbots con IA para responder de manera inmediata todas las consultas de los usuarios sin intervención humana, mejorando la eficiencia operativa y permitiendo que tu equipo de soporte se concentre en tareas más complejas y de mayor valor.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-webchat-cardsOne-card-image"
              src={imageOneDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-webchat-cardsOne-column">
          {/* columna */}
          <section className="container-experience-webchat-cardsOne-card" style={{ background: "#FADEDD", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-webchat-cardsOne-card-title">Enrutamiento a agentes en vivo</p>
              <p className="container-experience-webchat-cardsOne-card-description">
              Permite que tus clientes sean atendidos por agentes humanos. Analiza la complejidad de la consulta, el historial del cliente y las especializaciones de los agentes para asegurar que cada cliente sea atendido por el agente más calificado para las necesidades específicas.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-webchat-cardsOne-card-image"
                src={imageTwoDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>

      {/* Contenedor de dos columnas */}
      <div className="container-experience-webchat-cardsTwo">
        {/* una sola fila */}
        <section className="container-experience-webchat-cardsTwo-card" style={{ background: "#FADEDD", padding: "32px 0px 0px" }}>
          <section style={{ padding: "0px 32px" }} >
            <p className="container-experience-webchat-cardsTwo-card-title">Integraciones con APIs</p>
            <p className="container-experience-webchat-cardsTwo-card-description">
            Utiliza nuestras APIs para crear integraciones con tus plataformas internas como CRM o ERP, como Salesforce, HubSpot, Zoho y más; permitiendo un intercambio de datos en tiempo real. Esto facilita una visión 360 grados del cliente y personaliza la interacción.
            </p>
            <br />
          </section>
          <section>
            <img
              className="container-experience-webchat-cardsTwo-card-image"
              src={imageThreeDesktop}
              alt="foto"
              loading="lazy"
            />
          </section>
        </section>
        {/* Dos filas */}
        <div className="container-experience-webchat-cardsTwo-column">
          {/* columna */}
          <section className="container-experience-webchat-cardsTwo-card" style={{ background: "#EEEAFB", padding: "32px 32px 0px" }}>
            <section>
              <p className="container-experience-webchat-cardsTwo-card-title">Refuerza estrategias Cross y up selling</p>
              <p className="container-experience-webchat-cardsTwo-card-description">
              Identifica oportunidades de venta cruzada o adicional, basándote en el comportamiento de compra y las preferencias del cliente. Ofrece recomendaciones personalizadas durante las interacciones para maximizar el valor del cliente a largo plazo.
              </p>
              <br />
            </section>
            <section>
              <img
                className="container-experience-webchat-cardsTwo-card-image"
                src={imageFourDesktop}
                alt="foto"
                loading="lazy"
              />
            </section>
          </section>
        </div>
      </div>


      <div className="container-experience-webchat-cards-mobile" ref={fromRef}>
        <Slider {...activateSliderTestimonies} ref={sliderRef}>
          {data.map((elemento, i) => {
            return (
              <section key={i}>
                <div className="container-experience-webchat-cards-mobile-card" style={{ background: elemento.background }}>
                  <section>
                    <p className="container-experience-webchat-cards-mobile-card-title">{elemento.title}</p>
                    <p className="container-experience-webchat-cards-mobile-card-description">
                      {elemento.description}
                    </p>
                    <br />
                  </section>
                  <section>
                    <img
                      className="container-experience-webchat-cards-mobile-card-image"
                      src={elemento.image}
                      alt="foto"
                      loading="lazy"
                    />
                  </section>
                </div>
              </section>
            )
          })}
        </Slider>
      </div>
    </section>
  )
}
export default Experience
