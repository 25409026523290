import React from "react"

export const TitleUnderlineOne = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="201" height="12" viewBox="0 0 201 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M37.296 10.2203C32.5026 9.45373 3.03169 11.3997 0.929362 11.2471C-0.935672 10.9844 0.38874 5.1128 1.84039 4.19484C2.35507 3.86856 3.02601 3.78335 4.62918 3.87698C6.03524 3.95823 15.7261 3.52095 39.1704 2.55416C39.6294 2.7245 40.7901 2.08951 41.0649 2.30339C41.2744 2.42752 44.6793 2.38707 49.7409 2.28982C55.4204 2.17962 70.8818 1.79463 78.5071 1.64616C89.761 1.41952 85.5279 1.45488 98.4116 1.27178C101.107 1.23254 99.0011 1.59927 102.17 1.45127C108.929 1.14112 123.702 1.44428 128.818 1.16309C132.92 0.93709 133.814 0.925838 137.757 1.16788C140.91 1.3604 163.165 1.41929 165.678 1.0774C166.283 0.975041 166.743 1.02812 166.729 1.15646C166.992 1.16925 181.804 1.60108 181.869 1.70757C181.974 1.83664 182.42 1.91198 182.854 1.8309C183.644 1.69058 199.747 1.6725 199.968 2.05355C200.593 3.15182 197.844 7.63541 195.905 8.70114C193.874 9.81655 183.893 8.20838 175.319 8.73071C175.319 8.73071 172.901 8.6879 169.273 8.6153C133.85 7.95045 135.057 8.26499 128.299 8.58073C127.342 8.18397 123.721 9.12209 121.477 8.4438C120.545 8.16446 113.96 8.1239 112.486 8.39401C112.17 8.45348 109.83 8.46141 106.425 8.4572C103.034 8.45306 98.5778 8.4312 94.0688 8.45927C91.8077 8.47327 89.5335 8.4816 87.3775 8.50741C85.2083 8.52755 83.1442 8.57626 81.3167 8.62643C77.6485 8.72668 74.9134 8.8606 74.0708 9.06759C67.3834 8.4792 43.6868 9.8688 37.296 10.2203Z" fill="#FFBA00" />
    </svg>
  </span>
)

export const TitleUnderlineTwo = ({ underline }) => (
  <span>
    {underline}
    <svg
      style={{ bottom: "-2px" }}
      width="131" height="12" viewBox="0 0 131 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.2511 10.14C21.1363 9.38373 1.97083 11.3931 0.604273 11.2451C-0.607759 10.9864 0.26599 5.11205 1.21179 4.19097C1.54713 3.86359 1.98354 3.77693 3.02568 3.86711C3.93969 3.94532 10.2414 3.48718 25.4864 2.46989C25.7844 2.63924 26.5405 2.00176 26.7186 2.21504C26.8546 2.33872 29.0685 2.29094 32.3596 2.18278C36.0525 2.06034 46.106 1.64205 51.0641 1.47715C58.3816 1.22627 55.6293 1.27075 64.0063 1.0599C65.7586 1.01485 64.3889 1.38611 66.4496 1.23129C70.8446 0.906579 80.4493 1.17791 83.7758 0.885708C86.4438 0.650868 87.025 0.63769 89.5876 0.871239C91.6376 1.05696 106.107 1.0679 107.742 0.720609C108.135 0.616942 108.434 0.669031 108.425 0.7974C108.596 0.80962 118.225 1.20954 118.267 1.31589C118.335 1.44473 118.625 1.5191 118.908 1.43709C119.421 1.29507 129.891 1.24229 130.034 1.62287C130.438 2.71978 128.641 7.20923 127.378 8.27912C126.055 9.3989 119.569 7.81225 113.994 8.35304C113.994 8.35304 112.421 8.31544 110.063 8.25066C87.0331 7.66213 87.8174 7.97407 83.4224 8.30437C82.8009 7.90967 80.4448 8.85557 78.9875 8.18213C78.3823 7.9048 74.1006 7.87843 73.1417 8.15171C72.9362 8.21187 71.4148 8.22484 69.2012 8.22796C66.9962 8.23113 64.0989 8.21887 61.1672 8.25665C59.6971 8.27552 58.2184 8.28875 56.8166 8.3192C55.4062 8.34402 54.0641 8.39717 52.8758 8.45128C50.4906 8.55943 48.712 8.69924 48.1637 8.90804C43.817 8.33407 28.4071 9.7747 24.2511 10.14Z" fill="#FFBA00" />
    </svg>
  </span>
)